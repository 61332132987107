import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import MachineTableRow from "./MachineTableRow/MachineTableRow";
import { useAdminContext } from "../../provider/AdminContext";
import AddMachineModal from "./AddMachine";
import CustomModal from "../CustomModal";
import EditMachineModal from "./UpdateModal";
import Spinner from "../Spinner";
import Paginator from "../Paginator";
import { addMachinApi, deleteMachineApi, getMachinesApi, updateMachineApi } from "../../api/api";
interface ApiError {
  message: string;
  statusCode?: number;
  data: {
    message: string;
  };
}
interface IMachineDetails {
  name: string;
  storeName: string;
  address: string;
  status: string;
  location: {
    lattitude: string;
    longitude: string;
  };
}
export default function MyMachineTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteMachineModal, setDeleteMachineModal] = useState(false);
  const [isEditMachine, setIsEditMachine] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState<string>("");
  const { addNewMachine, setAllMachines } = useAdminContext();
  const [machines, setMachines] = useState<Machine[]>([]);
  const [machinesData, setMachinesData] = useState<
    MachineListResponse | undefined
  >();
  const [selectedPage, setSelectedPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [machineDetails, setMachineDetails] = useState<IMachineDetails>({
    location: {
      lattitude: "",
      longitude: "",
    },
    name: "",
    storeName: "",
    address: "",
    status: "Active",
  });
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (hasMore && newPage * rowsPerPage >= machines.length) {
      setSelectedPage(selectedPage + 1);
      fetchMachines(selectedPage + 1);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (machinesData) {
      if (machinesData.data.machines.length > 0) {
        const newMachines =
          selectedPage === 1
            ? machinesData.data.machines
            : machinesData.data.machines.filter(
                (newMachine) =>
                  !machines.some(
                    (existingMachine) => existingMachine._id === newMachine._id
                  )
              );
        setMachines((prevMachines) =>
          selectedPage === 1 ? newMachines : [...prevMachines, ...newMachines]
        );
        setHasMore(true);
      } else {
        if (selectedPage === 1) {
          setMachines([]);
        }
        setHasMore(false);
      }
    }
  }, [machinesData, selectedPage]);
  const handleDeleteMachine = async () => {
    try {
      const response = await deleteMachineApi(selectedMachineId)
      if (response.data.success) {
        // await setMachines((prevMachines) =>
        //   prevMachines.filter((machine) => machine._id !== selectedMachineId)
        // );
        // setAllMachines(machines);
        fetchMachines();
      } else {
        console.error("Failed to delete machine:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred while deleting the machine", error);
    }
    setDeleteMachineModal(false);
  };
  const fetchMachines = async (pageIndex?: number) => {
    setIsLoading(true);
    try {
      const response = await getMachinesApi({pageIndex});
      if (response.data.success) {
        setMachinesData(response.data);

        const newMachines = response.data.data.machines.filter(
          (newMachine:NewMachine) =>
            !machines.some(
              (existingMachine) => existingMachine._id === newMachine._id
            )
        );
        await setMachines((prevMachines) => [...prevMachines, ...newMachines]);
        await setAllMachines(machines);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch machines", error);

      setIsLoading(false);
    }
  };
  const addMachine = async (newMachine: NewMachine) => {
    try {
      const response = await addMachinApi(newMachine)
      if (response.data.success) {
        // setMachines([...machines, response.data.data.machine]);
        // addNewMachine(response.data.data.machine);
        fetchMachines();
      }
    } catch (error) {
      console.error("Failed to fetch machines", error);
    }
  };
  const updateMachine = async (machineDetails: IMachineDetails) => {
    try {
      const response = await updateMachineApi(selectedMachineId,machineDetails)
      if (response.data.success) {
        const updatedMachine = response.data.data.machine;
        setMachines((prevMachines) =>
          prevMachines.map((machine) =>
            machine._id === updatedMachine._id ? updatedMachine : machine
          )
        );
        setAllMachines(machines);

        setIsEditMachine(false);
      }
    } catch (error) {
      console.error("Failed to fetch machines", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchMachines();
    };
    fetchData();
  }, []);

  const header = ["Machine Name", "Store Name", "Address", "Status", "Actions"];
  const slicedRows = machines.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.tabHeader}>
        <AddMachineModal onAddMachine={addMachine} />
      </Box>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table className={styles.table} aria-label="machine list table">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              {header.map((header, index) => (
                <TableCell
                  key={index}
                  align={index > 0 ? "center" : "left"}
                  className={styles.cell}
                  sx={{
                    ...(header === "Status"
                      ? { pl: 3 }
                      : header === "Actions"
                      ? { pl: "5%" }
                      : {}),
                    borderBottom: "1px solid rgb(181 181 181)",
                  }}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {header}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={header.length} align="center">
                  <Spinner />
                </TableCell>
              </TableRow>
            ) : slicedRows.length > 0 ? (
              slicedRows.map((row, index) => (
                <MachineTableRow
                  key={index}
                  {...row}
                  setDeleteMachineModal={setDeleteMachineModal}
                  setIsEditMachine={setIsEditMachine}
                  setSelectedMachineId={setSelectedMachineId}
                  setMachineDetails={setMachineDetails}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={header.length} align="center">
                  <Typography variant="body1">No data found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paginator
          totalRows={machinesData?.data.totalCount || 0}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <CustomModal
        open={deleteMachineModal}
        setOpen={setDeleteMachineModal}
        padding={5}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" textAlign="center" color="white">
            Delete this Post?
          </Typography>
          <Typography mt={2} textAlign="center" color="#E0FD03">
            Are you sure you want to delete this machine?
          </Typography>
          <Box
            mt={4}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <Button
              onClick={() => setDeleteMachineModal(false)}
              variant="outlined"
              sx={{
                paddingX: 8,
                height: 40,
                borderRadius: 10,
                color: "#E0FD03",
                borderColor: "#E0FD03",
                "&:hover": {
                  backgroundColor: "#e8ff3c",
                  color: "black",
                  borderColor: "black",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteMachine}
              variant="contained"
              sx={{
                paddingX: 8,
                height: 40,
                borderRadius: 10,
                backgroundColor: "#E0FD03",
                color: "black",
                "&:hover": {
                  backgroundColor: "#e8ff3c",
                  color: "black",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </CustomModal>
      {isEditMachine && (
        <EditMachineModal
          setIsEditMachine={setIsEditMachine}
          updateMachine={updateMachine}
          isEdit={isEditMachine}
          machineDetail={machineDetails}
        />
      )}
    </Box>
  );
}
