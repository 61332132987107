import { ReactNode } from "react";
import { Box } from "@mui/material";
import Sidebar from "../Sidebar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#96a42d",
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row",
        },
        color: "white",
        padding: 3,
        gap: 3,
        boxSizing: "border-box",
        overflowY: "hidden",
        height: "100vh",
      }}
    >
      <Sidebar />
      <Box
        sx={{
          width: "100%",
          height: "unset",
          overflowY: "scroll",

          margin: {
            xs: "unset",
            lg: "10px",
          },
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
