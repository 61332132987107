import { Box, Button, Typography, Avatar, IconButton } from "@mui/material";
import { createStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import Spinner from "../Spinner";
import { getCommentsApi } from "../../api/api";

interface ViewLikesModalProps {
  onClose: () => void;
  selectedFeed: Feed | null;
}

const useStyles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minWidth: "300px",
      height: "100%",
      color: "white",
      overflowY: "scroll",
      paddingBottom: "3rem",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    headingContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      backgroundColor: "black",
      zIndex: 100,
      padding: "0.5rem 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      color: "#E0FD03",
      fontWeight: "bold",
      fontSize: "1.25rem",
    },
    content: {
      paddingTop: "3rem",
    },
    noContent: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    spinnerBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    userContainer: {
      display: "flex",
      marginBottom: "0.5rem",
      alignItems: "center",
      padding: "0.5rem",
      borderRadius: "8px",
      backgroundColor: "#1C1C1C",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: "#2C2C2C",
      },
    },
    avatar: {
      marginRight: "0.5rem",
      width: 40,
      height: 40,
    },
    commentTextContainer: {
      flex: 1,
      padding: "0 0.5rem",
    },
    timestamp: {
      fontSize: "0.875rem",
      color: "#B0B0B0",
    },
    closeBtnContainer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "black",
      zIndex: 100,
      padding: "0.5rem 0",
      display: "flex",
      justifyContent: "center",
    },
    closeBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      color: "#E0FD03",
      width: "max-content",
      border: "1px solid #E0FD03",
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
        borderColor: "black",
      },
    },
  });

const ViewCommentsModal: React.FC<ViewLikesModalProps> = ({
  onClose,
  selectedFeed,
}) => {
  const styles = useStyles();
  const [comments, setComments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchComments = async () => {
    if (selectedFeed) {
      setIsLoading(true);
      try {
        const response = await getCommentsApi({ feed: selectedFeed._id });
        setComments(response.data.data.comments);
      } catch (error) {
        console.error("Failed to fetch comments", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchComments();
  }, [selectedFeed]);

  const renderCommentsContent = (): JSX.Element => {
    if (isLoading) {
      return (
        <Box sx={styles.spinnerBox}>
          <Spinner />
        </Box>
      );
    }

    if (comments?.length > 0) {
      return (
        <>
          {comments.map((comment) => (
            <Box key={comment._id} sx={styles.userContainer}>
              <Avatar
                sx={styles.avatar}
                alt={comment.userId.name}
                src={comment.userId.profileImage}
              />
              <Box sx={styles.commentTextContainer}>
                <Typography variant="body1" fontWeight="bold">
                  {comment.userId.name}
                </Typography>
                <Typography sx={styles.timestamp}>
                  {new Date(comment.createdAt).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </Typography>
                <Typography variant="body2" mt="0.25rem">
                  {comment.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      );
    }

    return (
      <Box sx={styles.noContent}>
        <Typography variant="body2">No comments yet.</Typography>
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.headingContainer}>
        <Typography sx={styles.heading}>Comments</Typography>
      </Box>

      <Box sx={styles.content}>{renderCommentsContent()}</Box>

      <Box sx={styles.closeBtnContainer}>
        <Button onClick={onClose} sx={styles.closeBtn}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ViewCommentsModal;
