import { AxiosResponse } from "axios";
import { buildQueryString, QueryParams } from "../utils/functions";
import { HttpClient } from "./axoisInstance";

const loginApi = (data: { email: string; password: string }) => {
  const apiData = {
    url: `/admin/login`,
    method: "POST",
    data,
  };
  return HttpClient.custom(apiData);
};

const deleteMachineApi = (selectedMachineId: string) => {
  const apiData = {
    url: `/machine/${selectedMachineId}`,
    method: "DELETE",
  };
  return HttpClient.custom(apiData);
};

const getMachinesApi = (
  params: QueryParams
): Promise<AxiosResponse<MachineListResponse>> => {
  const queryString = buildQueryString(params);
  const apiData = {
    url: `/machine${queryString}`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
};

const addMachinApi = (
  data: NewMachine
): Promise<AxiosResponse<AddMachineResponse>> => {
  const apiData = {
    url: `/machine`,
    method: "POST",
    data,
  };
  return HttpClient.custom(apiData);
};

const updateMachineApi = (
  selectedMachineId: string,
  data: NewMachine
): Promise<AxiosResponse<AddMachineResponse>> => {
  const apiData = {
    url: `/machine/${selectedMachineId}`,
    method: "PUT",
    data,
  };
  return HttpClient.custom(apiData);
};

const getTransactionsApi = (
  params: QueryParams
): Promise<AxiosResponse<TransactionListResponse>> => {
  const queryString = buildQueryString(params);
  const apiData = {
    url: `/transactions${queryString}`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
};

const getLevelsApi = (
  params: QueryParams
): Promise<AxiosResponse<LevelListResponse>> => {
  const queryString = buildQueryString(params);
  const apiData = {
    url: `/level${queryString}`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
};

const addLevelApi = (
  data: AddLevelRequest
): Promise<AxiosResponse<UpdateLevelResponse>> => {
  const apiData = {
    url: `/level`,
    method: "POST",
    data,
  };
  return HttpClient.custom(apiData);
};

const updateLevelApi = (
  selectedLevelId: string,
  data: UpdateLevelRequest
): Promise<AxiosResponse<UpdateLevelResponse>> => {
  const apiData = {
    url: `/level/${selectedLevelId}`,
    method: "PUT",
    data,
  };
  return HttpClient.custom(apiData);
};

const deleteLevelApi = (selectedLevelId: string) => {
  const apiData = {
    url: `/level/${selectedLevelId}`,
    method: "DELETE",
  };
  return HttpClient.custom(apiData);
};

const getRewardsApi = (): Promise<AxiosResponse<RewardsListResponse>> => {
  const apiData = {
    url: `/rewards`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
};

const addRewardApi = (
  data: AddUpdateRewardRequest
): Promise<AxiosResponse<AddUpdateRewardResponse>> => {
  const apiData = {
    url: `/rewards`,
    method: "POST",
    data,
  };
  return HttpClient.custom(apiData);
};

const updateRewardApi = (
  selectedRewardId: string,
  data: AddUpdateRewardRequest
): Promise<AxiosResponse<AddUpdateRewardResponse>> => {
  const apiData = {
    url: `/rewards/${selectedRewardId}`,
    method: "PUT",
    data,
  };
  return HttpClient.custom(apiData);
};

const fileUpload = (
  data: FormData
): Promise<AxiosResponse<FileUploadResponse>> => {
  const apiData = {
    url: `/file/upload-single`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return HttpClient.custom(apiData);
};

const getFeedsApi = (
  params: QueryParams
): Promise<AxiosResponse<FeedListResponse>> => {
  const queryString = buildQueryString(params);
  const apiData = {
    url: `/feed${queryString}`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
};

const addFeedApi = (
  data: AddUpdateFeedApiRequest
): Promise<AxiosResponse<AddEditDeleteFeedApiResponse>> => {
  const apiData = {
    url: `/feed`,
    method: "POST",
    data,
  };
  return HttpClient.custom(apiData);
};

const editFeedApi = (
  selectedFeedId: string,
  data: AddUpdateFeedApiRequest
): Promise<AxiosResponse<AddEditDeleteFeedApiResponse>> => {
  const apiData = {
    url: `/feed/${selectedFeedId}`,
    method: "PUT",
    data,
  };
  return HttpClient.custom(apiData);
};

const deleteFeedApi = (feedId: string):Promise<AxiosResponse<AddEditDeleteFeedApiResponse>> => {
  const apiData = {
    url: `/feed/${feedId}`,
    method: "DELETE",
  };
  return HttpClient.custom(apiData);
};

const getCommentsApi = (params: QueryParams): Promise<AxiosResponse<any>> => {
  const queryString = buildQueryString(params);
  const apiData = {
    url: `/comments${queryString}`,
    method: "GET",
  };
  return HttpClient.custom(apiData);
}

export {
  getMachinesApi,
  loginApi,
  deleteMachineApi,
  getTransactionsApi,
  addMachinApi,
  updateMachineApi,
  getLevelsApi,
  updateLevelApi,
  addLevelApi,
  deleteLevelApi,
  getRewardsApi,
  updateRewardApi,
  addRewardApi,
  fileUpload,
  getFeedsApi,
  addFeedApi,
  editFeedApi,
  deleteFeedApi,
  getCommentsApi
};
