import { Box, Button, Typography } from "@mui/material";
import { createStyles } from "@mui/styles";
import React from "react";

interface DeleteConfirmationProps {
  onConfirm: (feed: Feed | null) => void;
  onClose: () => void;
  selectedFeed: Feed | null;
}

const useStyles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    btnBox: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: 3,
    },
    cancelBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      color: "#E0FD03",
      borderColor: "#E0FD03",
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
        borderColor: "black",
      },
    },
    deleteBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      backgroundColor: "#E0FD03",
      color: "black",
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
      },
    },
  });

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  onClose,
  selectedFeed,
  onConfirm,
}) => {
  const handleDelete = () => {
    onConfirm(selectedFeed);
    onClose();
  };

  const styles = useStyles();
  return (
    <Box sx={styles.root}>
      <Typography variant="h5" textAlign="center" color="white">
        Delete this feed?
      </Typography>
      <Typography mt={2} textAlign="center" color="#E0FD03">
        Are you sure you want to delete this feed?
      </Typography>
      <Box mt={4} sx={styles.btnBox}>
        <Button onClick={onClose} variant="outlined" sx={styles.cancelBtn}>
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" sx={styles.deleteBtn}>
          Delete
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteConfirmation;
