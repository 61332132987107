import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import {
  Box,
  Paper,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./index.module.css";
import { useAdminContext } from "../../provider/AdminContext";
import TransactionTableRow from "../../components/TransactionTableRow";

import Spinner from "../../components/Spinner";
import Paginator from "../../components/Paginator";
import { getTransactionsApi } from "../../api/api";
const TransactionHistory: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeposit, setIsDeposit] = useState(true);
  const toggleSpendingHandler = () => {
    if (isDeposit) {
      setIsDeposit(false);
      return;
    }
  };
  const toggleDepositHandler = () => {
    if (!isDeposit) {
      setIsDeposit(true);
      return;
    }
  };
  const header = [
    "User Id",
    "Amount",
    "Transaction Date",
    "Status",
    // "Actions",
  ];
  const [transactionsData, setTransactionsData] = useState<
    TransactionListResponse | undefined
  >();
  const [selectedPage, setSelectedPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { setAllTransactions } = useAdminContext();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (hasMore && newPage * rowsPerPage >= transactions.length) {
      setSelectedPage(selectedPage + 1);
      fetchTransactions(selectedPage + 1);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchTransactions = async (pageIndex?: number) => {
    setIsLoading(true);
    const type = isDeposit ? "ADD" : "USED";
    try {
      const response = await getTransactionsApi({ type });
      if (response.data.success) {
        setTransactionsData(response.data);
        const newTransactions = response.data.data.transactions.filter(
          (newTransaction) =>
            !transactions.some(
              (existingTransaction) =>
                existingTransaction._id === newTransaction._id
            )
        );
        await setTransactions((prevTransactions) => [
          ...prevTransactions,
          ...newTransactions,
        ]);
        setAllTransactions(transactions);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch transactions", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchTransactions();
      setPage(0);
      setSelectedPage(1);
      setTransactions([]);
    };
    fetchData();
  }, [isDeposit]);
  useEffect(() => {
    if (transactionsData) {
      if (transactionsData.data.transactions.length > 0) {
        const newTransactions =
          selectedPage === 1
            ? transactionsData.data.transactions
            : transactionsData.data.transactions.filter(
                (newTransaction) =>
                  !transactions.some(
                    (existingMachine) =>
                      existingMachine._id === newTransaction._id
                  )
              );
        setTransactions((prevTransaction) =>
          selectedPage === 1
            ? newTransactions
            : [...prevTransaction, ...newTransactions]
        );
        setHasMore(true);
      } else {
        if (selectedPage === 1) {
          setTransactions([]);
        }
        setHasMore(false);
      }
    }
  }, [transactionsData, selectedPage]);

  const slicedRows = transactions
    ? transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : [];
  return (
    <Layout>
      <Box className={styles.container}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              fontFamily: "Poppins",
              textAlign: {
                xs: "center",
                sm: "start",
              },
            }}
          >
            Transaction History
          </Typography>
        </Box>

        <Box className={styles.toggleContainer}>
          <Box className={styles.toggleItemBox}>
            <Typography
              className={`${styles.toggleItem} ${
                isDeposit ? styles.active : ""
              }`}
              onClick={toggleDepositHandler}
            >
              Deposit Transactions
            </Typography>
          </Box>
          <Box className={styles.toggleItemBox}>
            <Typography
              className={`${styles.toggleItem} ${
                !isDeposit ? styles.active : ""
              }`}
              onClick={toggleSpendingHandler}
            >
              Spending Transactions
            </Typography>
          </Box>
        </Box>
        {/* Table Container Here */}

        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table className={styles.table} aria-label="machine list table">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                {header.map((header, index) => (
                  <TableCell
                    key={index}
                    align={index > 0 ? "center" : "left"}
                    className={styles.cell}
                    sx={{
                      borderBottom: "1px solid rgb(181 181 181)",
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography
                        sx={{ fontSize: 14, color: "rgba(0, 0, 0, 0.5)" }}
                      >
                        {header}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={header.length} align="center">
                    <Spinner />
                  </TableCell>
                </TableRow>
              ) : slicedRows.length > 0 ? (
                slicedRows.map((row, index) => (
                  <TransactionTableRow key={index} {...row} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={header.length} align="center">
                    <Typography variant="body1">No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Paginator
            totalRows={transactionsData?.data.totalCount || 0}
            rowsPerPage={rowsPerPage}
            currentPage={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default TransactionHistory;
