import React, { CSSProperties } from "react";
import styles from "./index.module.css";
import { Box } from "@mui/material";
interface SpinnerProps {
  sx?: CSSProperties
}

const Spinner: React.FC<SpinnerProps> = ({ sx }) => {
  return <Box className={styles.spinner} sx={sx} />;
};
export default Spinner;
