import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { NavLink } from "react-router-dom";
import styles from "./index.module.css";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import MySVGIcon from "../../assets/tageto_svg.svg";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import React, { useState } from "react";

const navLinks = [
  {
    name: "Machine List",
    icon: HomeIcon,
    link: "/",
  },
  {
    name: "Wallet",
    icon: AccountBalanceWalletIcon,
    link: "/transactions",
  },
  {
    name: "Levels",
    icon: StarIcon,
    link: "/levels",
  },
  {
    name: "Feeds",
    icon: VideoLibraryIcon,
    link: "/feeds",
  },
  {
    name: "Logout",
    icon: LogoutIcon,
    link: "/login",
  },
];

export interface IUser {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  isActive: boolean;
  password: string;
  tasks: object[];
  role: string;
}
const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const handleSidebar = () => {
    setIsOpen(!isOpen);
    console.log("Now Open - " + isOpen);
  };
  return (
    <Box
      sx={{
        backgroundColor: "#161d2f",
        padding: {
          xs: 0,
          sm: 2,
        },
        borderRadius: 2,
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
          lg: "column",
        },
        alignItems: "center",
        justifyContent: {
          sm: "space-between",
          lg: "start",
        },
        width: {
          sm: "100%",
          md: "100%",
          lg: 250,
        },
        height: {
          xs: "unset",
          sm: "80px",
          md: "80px",
          lg: "auto",
        },
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      className="sidebar"
    >
      {/* <Hidden smDown> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          width: {
            xs: "100%",
            sm: "200px",
          },
          height: {
            xs: `${isOpen ? "200px" : "68px"}`,
            lg: "60px",
          },
        }}
      >
        {isOpen ? (
          <CloseIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fontSize: "1.5rem",
              display: {
                sx: "unset",
                sm: "hidden",
              },
              zIndex: "1",
            }}
            onClick={handleSidebar}
          />
        ) : (
          <MenuIcon
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              fontSize: "1.5rem",
              display: {
                sx: "unset",
                sm: "hidden",
              },
              zIndex: "1",
            }}
            onClick={handleSidebar}
          />
        )}

        <img
          src={MySVGIcon}
          alt="icon"
          style={{
            width: "350px",
            position: "relative",
            left: "-9px",
          }}
          className={styles.icon}
        />
      </Box>
      {/* </Hidden> */}

      <Box
        sx={{
          paddingTop: {
            xs: "0",
            sm: "16px",
          },
          paddingBottom: {
            xs: "20px",
            sm: "16px",
          },
          display: {
            xs: `${isOpen ? "flex" : "none"}`,
            sm: "flex",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
            lg: "column",
          },
          gap: 2,
          height: '350px',
          zIndex: 1,
        }}
      >
        {navLinks.map((item) => {
          if (item.name === "Logout") {
            return (
              <NavLink
                key={item.name}
                to={item.link}
                style={{ textDecoration: "none" }}
                onClick={handleLogout}
                className={({ isActive }) =>
                  isActive ? `${styles.active}` : ""
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    color: "white",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#dedede",
                    },
                  }}
                >
                  <item.icon />
                  {/* <Hidden smDown> */}
                  <Typography
                    variant="h5"
                    component="h6"
                    fontWeight={400}
                    fontSize={18}
                  >
                    {item.name}
                  </Typography>
                  {/* </Hidden> */}
                </Box>
              </NavLink>
            );
          }
          return (
            <NavLink
              key={item.name}
              to={item.link}
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? `${styles.active}` : "")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  color: "white",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#dedede",
                  },
                }}
              >
                <item.icon />
                {/* <Hidden smDown> */}
                <Typography
                  variant="h5"
                  component="h6"
                  fontWeight={400}
                  fontSize={18}
                >
                  {item.name}
                </Typography>
                {/* </Hidden> */}
              </Box>
            </NavLink>
          );
        })}
      </Box>
    </Box>
  );
};

export default Sidebar;
