import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./index.module.css";
import AddIcon from "@mui/icons-material/Add";
interface Props {
  handleAddLevel: () => void;
}
const AddLevelCard: React.FC<Props> = ({ handleAddLevel }) => {
  return (
    <Box>
      <Box className={styles.card} onClick={handleAddLevel}>
        <AddIcon />
        <Typography>Add New Level</Typography>
      </Box>
    </Box>
  );
};

export default AddLevelCard;
