import React, { useState } from "react";
import Layout from "../../components/Layout";
import styles from "./index.module.css";
import {
  Box,
  Typography,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/Spinner";
import FeedsTable from "../../components/FeedsTable/FeedsTable";


const Feeds: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box className={styles.spinnerBox}>
          <Spinner
            sx={{
              width: "60px",
              height: "60px",
            }}
          />
        </Box>
      );
    }

    return (
      <>
        <FeedsTable/>
      </>
    );
  };

  return (
    <Layout>
      <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontFamily: "Poppins",
            textAlign: {
              xs: "center",
              sm: "start",
            },
          }}
        >
          Manage Feeds
        </Typography>
        { renderContent() }
    </Layout>
  );
};

export default Feeds;