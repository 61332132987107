import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Fade,
  Backdrop,
} from "@mui/material";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IMachineDetails {
  name: string;
  storeName: string;
  address: string;
  status: string;
  location: {
    lattitude: string;
    longitude: string;
  };
}
const EditMachineModal: React.FC<{
  isEdit: boolean;
  machineDetail: IMachineDetails;
  updateMachine: (machine: IMachineDetails) => void;
  setIsEditMachine: (isEditMachine: boolean) => void;
}> = ({ isEdit, machineDetail, updateMachine, setIsEditMachine }) => {
  const [open, setOpen] = useState(isEdit);
  const [machineDetails, setMachineDetails] =
    useState<IMachineDetails>(machineDetail);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMachineDetails({ ...machineDetails, [name]: value });
  };

  const handleSubmit = () => {
    const { name, storeName, address } = machineDetails;
    if (!name || !storeName || !address) {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
      return;
    }
    updateMachine(machineDetails);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditMachine(false);
  };
  useEffect(() => {}, []);
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "black",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" color={"#e0fd03"}>
              Edit Machine
            </Typography>
            <TextField
              label="Machine Name"
              name="name"
              value={machineDetails.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white", // Text color
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <TextField
              label="Store Name"
              name="storeName"
              value={machineDetails.storeName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <TextField
              label="Address"
              name="address"
              value={machineDetails.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={handleClose} sx={{ mr: 2, color: "#e0fd03" }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#e0fd03",
                  color: "black",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default EditMachineModal;
