import { Box, Modal } from "@mui/material";
import { PropsWithChildren } from "react";
import { SxProps } from '@mui/system';
import styles from "./index.module.css";

interface Props extends PropsWithChildren {
  open: boolean;
  setOpen: (value: boolean) => void;
  width?: string;
  padding?: number;
  sx?: SxProps;
  onClose?: () => void; 
}

export default function CustomModal(props: Props) {
  const { children, width, open, setOpen, sx, onClose } = props;

  const handleClose = () => {
    setOpen(!open);
    if (onClose) onClose();  
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.container} style={{ width: `${width}` }} sx={sx}>
        {children}
      </Box>
    </Modal>
  );
}
