import { TableCell, TableRow, IconButton, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "../../../assets/tableEdit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/tableDelete.svg";
import styles from "./index.module.css";
interface IMachineDetails {
  name: string;
  storeName: string;
  address: string;
  status: string;
  location: {
    lattitude: string;
    longitude: string;
  };
}
interface Props extends Machine {
  key: number;
  setDeleteMachineModal: (value: boolean) => void;
  setIsEditMachine: (value: boolean) => void;
  setSelectedMachineId: (value: string) => void;
  setMachineDetails: (machine: IMachineDetails) => void;
}

export default function MachineTableRow(props: Props) {
  const {
    _id,
    name,
    storeName,
    address,
    status,
    location,
    setDeleteMachineModal,
    setIsEditMachine,
    setSelectedMachineId,
    setMachineDetails,
  } = props;
  const handleEditMachine = () => {
    setIsEditMachine(true);
    setSelectedMachineId(_id);
    setMachineDetails({
      name,
      storeName,
      address,
      status,
      location: {
        longitude: location.longitude,
        lattitude: location.lattitude,
      },
    });
  };

  const handleDeleteMachine = () => {
    setSelectedMachineId(_id);
    setDeleteMachineModal(true);
  };

  return (
    <TableRow className={styles.row}>
      <TableCell className={styles.cell} align="left">
        <Typography color="rgba(12, 143, 252, 1)">{name}</Typography>
      </TableCell>
      <TableCell className={styles.cell} align="left">
        <Typography noWrap sx={{ color: "black" }}>
          {storeName || "-"}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell} align="left">
        <Typography>{address}</Typography>
      </TableCell>
      <TableCell
        sx={{
          fontSize: 14,
          whiteSpace: "nowrap",
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "8px 16px",
          minWidth: 110,
        }}
        align="left"
      >
        <Typography
          color="rgba(12, 143, 252, 1)"
          sx={{
            color: `${status === "Active" ? "#39b239" : "#ca3636"}`,
            width: "80px",
            fontWeight: "600",
            paddingLeft: "10px",
          }}
        >
          {status}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell} align="center">
        <IconButton onClick={handleEditMachine}>
          <EditIcon height={20} width={20} />
        </IconButton>
        <IconButton onClick={handleDeleteMachine}>
          <DeleteIcon height={20} width={20} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
