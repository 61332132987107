import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Fade,
  Backdrop,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { toastify, ToastifyType } from "../../../utils/functions";

const AddMachineModal: React.FC<{
  onAddMachine: (machine: NewMachine) => void;
}> = ({ onAddMachine }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>("");
  const [machineDetails, setMachineDetails] = useState<NewMachine>({
    location: {
      lattitude: "",
      longitude: "",
    },
    name: "",
    storeName: "",
    address: "",
    status: "Active",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMachineDetails((prevDetails) => {
      if (name === "lattitude" || name === "longitude") {
        if (Number(value) < 0) {
          setError("Value should be greater than 0");
        } else {
          setError("");
        }
        return {
          ...prevDetails,
          location: {
            ...prevDetails.location,
            [name]: value,
          },
        };
      }
      return { ...machineDetails, [name]: value };
    });
  };

  const handleSubmit = () => {
    const { name, storeName, address } = machineDetails;
    if (!name || !storeName || !address) {
      toastify({
        type: ToastifyType.ERROR,
        message: "Please fill in all required fields.",
      });
      return;
    }
    onAddMachine(machineDetails);
    setOpen(false);
    setMachineDetails({
      location: {
        lattitude: "",
        longitude: "",
      },
      name: "",
      storeName: "",
      address: "",
      status: "Active",
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          backgroundColor: "black",
          color: "#E0FD03",
          borderRadius: "20px",
          padding: "6px 10px",
          fontSize: "0.9rem",
          fontWeight: "600",
          fontFamily: "Poppins",
          transition: "all 0.5s ease-in-out",
          "&:hover": {
            backgroundColor: "#E0FD03",
            color: "black",
          },
        }}
      >
        <AddIcon
          sx={{
            border: "1px solid",
            borderRadius: "50%",
            marginRight: "8px",
            width: "0.8em",
            height: "0.8em",
          }}
        />
        Add New Machine
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "black",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" color={"#e0fd03"}>
              Add New Machine
            </Typography>
            <TextField
              label="Machine Name"
              name="name"
              value={machineDetails.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <TextField
              label="Store Name"
              name="storeName"
              value={machineDetails.storeName}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <TextField
              label="Address"
              name="address"
              value={machineDetails.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
              }}
            />
            <Box mt={2} display="flex" justifyContent="space-between" gap={2}>
              <TextField
                label="Longitude"
                name="longitude"
                value={machineDetails.location.longitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                placeholder="76.714638"
                type="number"
                error={error.length !== 0}
                helperText={
                  error && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontSize: 9,
                        fontWeight: 500,
                        margin: 0,
                      }}
                    >
                      {error}
                    </FormHelperText>
                  )
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    backgroundColor: "black",
                    "& fieldset": {
                      borderColor: "#e0fd03",
                    },
                    "&:hover fieldset": {
                      borderColor: "#e0fd03",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#e0fd03",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#e0fd03",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
              <TextField
                label="Lattitude"
                name="lattitude"
                value={machineDetails.location.lattitude}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                placeholder="30.697910"
                type="number"
                error={error.length !== 0}
                helperText={
                  error && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontSize: 9,
                        fontWeight: 500,
                        margin: 0,
                      }}
                    >
                      {error}
                    </FormHelperText>
                  )
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    backgroundColor: "black",
                    "& fieldset": {
                      borderColor: "#e0fd03",
                    },
                    "&:hover fieldset": {
                      borderColor: "#e0fd03",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#e0fd03",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#e0fd03",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={handleClose} sx={{ mr: 2, color: "#e0fd03" }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#e0fd03",
                  color: "black",

                  "&:hover": {
                    backgroundColor: "#e8ff3c",
                    color: "black",
                  },
                }}
              >
                Add Machine
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AddMachineModal;
