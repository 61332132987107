import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/levelEdit.svg";
import { ReactComponent as RewardIcon } from "../../assets/levelGift.svg";
import React from "react";
import styles from "./index.module.css";
interface Props {
  level: LevelWithRewards;
  setIsEditLevel: (v: boolean) => void;
  setIsRewardLevel: (v: boolean) => void;
  setCurrentLevel: (level: LevelWithRewards) => void;
}
const LevelCard: React.FC<Props> = ({
  level,
  setIsEditLevel,
  setIsRewardLevel,
  setCurrentLevel,
}) => {
  const handleEdit = () => {
    setIsEditLevel(true);
    setCurrentLevel(level);
  };
  const handleRewardClick = () => {
    console.log(level.reward);
    
    setIsRewardLevel(true);
    setCurrentLevel(level);
  };
  return (
    <Box>
      <Box className={styles.card}>
        
        <Typography className={styles.text}>Level</Typography>
        <Typography
          className={styles.text}
          sx={{
            color: "white !important",
            fontSize: "2.5rem",
            fontWeight: "700",
          }}
        >
          {level.level}
        </Typography>
        <Typography className={styles.text}>{level.points} Points</Typography>
        <Box
          sx={{
            position: "relative",
            bottom: "-14px",
          }}
        >
          <Tooltip title="Reward">
          <IconButton  onClick={handleRewardClick}>
            <RewardIcon height={20} width={20}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit level">
            <IconButton onClick={handleEdit}>
              <EditIcon height={20} width={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default LevelCard;
