import {
  Box,
  Button,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

interface AddFeedModalProps {
  handleClose: () => void;
  onAddFeed: (newFeed: NewFeed) => void;
  onEditFeed: (feed: EditFeed, selectedFeed: Feed) => void;
  selectedFeed: Feed | null;
}

const useStyles = (theme: Theme) =>
  createStyles({
    rootBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      bgcolor: "black",
      boxShadow: "24",
      p: 4,
      borderRadius: 2,
      minHeight: "60vh",
      [theme.breakpoints.down("sm")]: {
        overflowY: "scroll",
        maxHeight: "90vh",
      },
    },
    container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "2rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    formBox: {
      width: "320px",
    },
    mediaPreviewBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    mediaPreviewContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#333",
      width: "320px",
      height: "600px",
      maxHeight: "60vh", 
      borderRadius: 4,
      overflow: "hidden",
    },
    mediaContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        color: "white",
        backgroundColor: "black",
        "& fieldset": {
          borderColor: "#e0fd03",
        },
        "&:hover fieldset": {
          borderColor: "#e0fd03",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#e0fd03",
        },
      },
      "& .MuiInputLabel-root": {
        color: "white",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#e0fd03",
      },
      "& input:-webkit-autofill": {
        boxShadow: "0 0 0 100px black inset",
        WebkitTextFillColor: "white",
      },
    },
    footerBox: {
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
      gap: "1rem",
      paddingTop: "1rem",
    },
    cancelBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      color: "#E0FD03",
      border: '1px solid #E0FD03',
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
        borderColor: "black",
      },
      [theme.breakpoints.down("sm")]: {
        paddingX: 5,
      }
    },
    addBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      backgroundColor: "#E0FD03",
      color: "black",
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
      },
      [theme.breakpoints.down("sm")]: {
        paddingX: 5,
      }
    },
  });

const baseURL = `${process.env.REACT_APP_API_URL}/documents/`;

const AddEditFeedModal: React.FC<AddFeedModalProps> = ({
  handleClose,
  onAddFeed,
  onEditFeed,
  selectedFeed = null,
}) => {
  const [feedDetail, setFeedDetail] = useState<NewFeed>({
    title: "",
    description: "",
    media: null,
  });

  const [previewMedia, setPreviewMedia] = useState<{
    url: string | null;
    isVideo: boolean;
  }>({
    url: null,
    isVideo: false,
  });

  useEffect(() => {
    if (selectedFeed) {
      setFeedDetail({
        title: selectedFeed.title,
        description: selectedFeed.description,
        media: null,
      });
      setPreviewMedia({
        url: `${baseURL}${selectedFeed.media}`,
        isVideo: selectedFeed.media.endsWith(".mp4") || selectedFeed.media.endsWith(".webm") || selectedFeed.media.endsWith(".mov"),
      });
    }
  }, [selectedFeed]);

  const theme = useTheme();
  const styles = useStyles(theme);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    
    setPreviewMedia({ url: null, isVideo: false });

    if (file) {
      setFeedDetail({ ...feedDetail, media: file });

      const fileType = file.type;
      const isVideo = fileType.startsWith("video/");

      const previewUrl = URL.createObjectURL(file);

      setPreviewMedia((prv) => ({
        url: previewUrl,
        isVideo,
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFeedDetail({ ...feedDetail, [name]: value });
  };

  const handleSubmit = () => {
    if (selectedFeed) {
      if (!feedDetail.description || !feedDetail.title) {
        toast.error("Please fill required fields");
        return;
      }
      onEditFeed(feedDetail, selectedFeed);
    } else {
      if (!feedDetail.description || !feedDetail.media || !feedDetail.title) {
        toast.error("Please fill required fields");
        return;
      }
      onAddFeed(feedDetail);
    }
    handleClose();
  };

  const renderMediaPreview = () => {
    if (!previewMedia.url) {
      return (
        <Box sx={{ color: "white", textAlign: "center" }}>Media Preview</Box>
      );
    }

    if (previewMedia.isVideo) {
      return (
        <Box sx={styles.mediaContainer}>
          <video
            src={previewMedia.url}
            controls
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }} 
          />
        </Box>
      );
    }

    return (
      <img
        src={previewMedia.url}
        alt="Media"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain", 
        }} 
      />
    );
  };

  return (
    <Box sx={styles.rootBox}>
      <Box sx={styles.container}>
        <Box sx={styles.formBox}>
          <Typography variant="h6" component="h2" color={"#e0fd03"}>
            {selectedFeed ? "Edit" : "Add New"} Feed
          </Typography>
          <TextField
            label="Title"
            placeholder="Enter Title"
            name="title"
            value={feedDetail.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            sx={styles.textField}
          />
          <TextField
            label="Description"
            placeholder="Enter Description"
            name="description"
            value={feedDetail.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            sx={styles.textField}
          />
          {!selectedFeed && (
            <TextField
              name="logo"
              onChange={(e) =>
                handleFileChange(e as React.ChangeEvent<HTMLInputElement>)
              }
              fullWidth
              margin="normal"
              required
              type="file"
              inputProps={{ accept: "image/*, video/*" }}
              sx={styles.textField}
            />
          )}
        </Box>
        <Box sx={styles.mediaPreviewBox}>
          <Box sx={styles.mediaPreviewContainer}>{renderMediaPreview()}</Box>
        </Box>
      </Box>
      <Box sx={styles.footerBox}>
        <Button sx={styles.cancelBtn} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={styles.addBtn}
          onClick={handleSubmit}
        >
          {selectedFeed ? "Edit" : "Add"} Feed
        </Button>
      </Box>
    </Box>
  );
};

export default AddEditFeedModal;
