import { Box, Button, Typography, Avatar } from "@mui/material";
import { createStyles } from "@mui/styles";
import React from "react";

interface ViewLikesModalProps {
  onClose: () => void;
  selectedFeed: Feed | null;
}

const useStyles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minWidth: "300px",
      height: "100%",
      color: "white",
      overflowY: "scroll",
      paddingBottom: "3rem",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    headingContainer: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      backgroundColor: "black",
      zIndex: 100,
      padding: "0.5rem 0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    heading: {
      color: "#E0FD03",
      fontWeight: "bold",
      fontSize: "1.25rem",
    },
    content: {
      paddingTop: "3rem",
    },
    noContent: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    userContainer: {
      display: "flex",
      marginBottom: "0.5rem",
      alignItems: "center",
    },
    avatar: {
      marginRight: "0.5rem",
    },
    closeBtnContainer: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "black",
      zIndex: 100,
      padding: "0.5rem 0",
      display: "flex",
      justifyContent: "center",
    },
    closeBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      color: "#E0FD03",
      width: "max-content",
      border: "1px solid #E0FD03",
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
        borderColor: "black",
      },
    },
  });

const ViewLikesModal: React.FC<ViewLikesModalProps> = ({
  onClose,
  selectedFeed,
}) => {
  const styles = useStyles();

  if (!selectedFeed) {
    return null;
  }

  const renderLikedByContent = (): JSX.Element => {
    if (selectedFeed.likedBy.length > 0) {
      return (
        <>
          {selectedFeed.likedBy.map((user, index) => (
            <Box key={index} sx={styles.userContainer}>
              <Avatar
                src={user.profileImage}
                alt={user.name}
                sx={styles.avatar}
              />
              <Typography variant="body1">{user.name}</Typography>
            </Box>
          ))}
        </>
      );
    }
    return (
      <Box sx={styles.noContent}>
        <Typography variant="body2">No Likes yet.</Typography>
      </Box>
    );
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.headingContainer}>
        <Typography sx={styles.heading}>Liked By</Typography>
      </Box>

      <Box sx={styles.content}>{renderLikedByContent()}</Box>

      <Box sx={styles.closeBtnContainer}>
        <Button onClick={onClose} sx={styles.closeBtn}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ViewLikesModal;
