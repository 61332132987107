import { Box, Button } from "@mui/material";
import { createStyles } from "@mui/styles";
import React from "react";

interface ViewMediaModalProps {
  onClose: () => void;
  selectedFeed: Feed | null;
}

const useStyles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    mediaContainer: {
      width: "100%",
      height: "100%", 
      overflow: "hidden",
    },
    closeBtn: {
      paddingX: 8,
      height: 40,
      borderRadius: 10,
      marginTop: "1rem",
      color: "#E0FD03",
      border: '1px solid #E0FD03',
      "&:hover": {
        backgroundColor: "#e8ff3c",
        color: "black",
        borderColor: "black",
      },
    }
  });

const baseURL = `${process.env.REACT_APP_API_URL}/documents/`;

const ViewMediaModal: React.FC<ViewMediaModalProps> = ({
  onClose,
  selectedFeed,
}) => {
  const styles = useStyles();

  if (!selectedFeed) {
    return null;
  }

  const { media } = selectedFeed;
  const fullMediaUrl = `${baseURL}${media}`;
  const isVideo = media.endsWith(".mp4") || media.endsWith(".webm") || media.endsWith(".mov");

  return (
    <Box sx={styles.root}>
      <Box sx={styles.mediaContainer}>
        {isVideo ? (
          <video controls style={{ width: "100%", height: "100%", objectFit: "contain" }}>
            <source src={fullMediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={fullMediaUrl} alt="Media" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        )}
      </Box>
      <Button onClick={onClose} sx={styles.closeBtn}>Close</Button>
    </Box>
  );
};

export default ViewMediaModal;
