import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Tooltip,
  useTheme,
  Theme,
} from "@mui/material";
import React, { useState } from "react";
import CustomModal from "../CustomModal";
import AddEditFeedModal from "./AddEditFeedModal";
import AddIcon from "@mui/icons-material/Add";
import {
  addFeedApi,
  deleteFeedApi,
  editFeedApi,
  getFeedsApi,
} from "../../api/api";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { uploadMedia } from "../../utils/functions";
import { ReactComponent as EditIcon } from ".././../assets/tableEdit.svg";
import { ReactComponent as DeleteIcon } from ".././../assets/tableDelete.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteConfirmation from "./ConfirmDeleteModal";
import ViewMediaModal from "./ViewMediaModal";
import ViewLikesModal from "./ViewLikesModal";
import ViewCommentsModal from "./ViewCommentsModal";
import Spinner from "../Spinner";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { createStyles } from "@mui/styles";

const header = [
  "Title",
  "Description",
  "Media (Video/Image)",
  "Comments & Likes",
  "Actions",
];
const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      fontFamily:  'Livvic'
    },
    tabHeader:{
      display: 'flex',
      flexDirection: 'row',
      width: '99%',
      marginBottom: '10px',
      marginTop: '2px',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    tableContainer: {
      width: '100%',
      overflowX: 'auto',
      border: 'none',
      boxShadow: 'none',
      scrollbarWidth: 'thin',
      maxHeight: '550px',
    },
    tableHeader: {
      position: 'sticky',
      top: 0,
      background: 'white',
      zIndex: 1,
    },
    table: {
      margin: '0 3px',
      borderCollapse: 'separate',
      borderSpacing: '0 18px',
      maxWidth: '99%',
    },
    addFeedBtn: {
      backgroundColor: 'black',
      color: '#E0FD03',
      borderRadius: '20px',
      padding: '6px 10px',
      fontSize: '0.9rem',
      fontWeight: 600,
      fontFamily: "'Poppins', sans-serif",
      transition: 'all 0.5s ease-in-out',
      '&:hover': {
        backgroundColor: '#E0FD03',
        color: 'black',
      },
    },
    addIcon: {
      border: '1px solid',
      borderRadius: '50%',
      marginRight: '8px',
      width: '0.8em',
      height: '0.8em',
    },
    paginationBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    addEditFeedModal: {
      maxWidth: "800px",
      padding: "20px",
    },
    viewMediaModal: {
      maxWidth: "800px",
      minWidth: '300px',
      height: "80vh",
      overflowY: "hidden",
      padding: "20px",
    },
    viewLikesModal: {
      height: "60vh",
      overflow: "hidden",
      padding: "20px",
    },
    viewCommentsModal: {
      height: "60vh",
      overflow: "hidden",
      padding: "20px",
    }
  });


const FeedsTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddEditFeedOpen, setIsAddEditFeedOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isViewMediaOpen, setIsViewMediaOpen] = useState(false);
  const [isViewLikesOpen, setIsViewLikesOpen] = useState(false);
  const [isViewCommentsOpen, setIsViewCommentsOpen] = useState(false);
  const [feeds, setFeeds] = useState<Feed[]>([]);
  const [selectedFeed, setSelectedFeed] = useState<Feed | null>(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  });

  React.useEffect(() => {
    fetchFeeds(1, pagination.pageSize);
  }, []);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
    fetchFeeds(newPage + 1, pagination.pageSize);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
    }));
    fetchFeeds(1, parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setIsAddEditFeedOpen(false);
    setConfirmDelete(false);
    setIsViewMediaOpen(false);
    setIsViewLikesOpen(false);
    setIsViewCommentsOpen(false);
    setSelectedFeed(null);
  };

  const fetchFeeds = async (pageIndex = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const response = await getFeedsApi({ pageIndex, pageSize });
      if (response.data.success) {
        setFeeds(response.data.data.feeds);
        setPagination({
          currentPage: response.data.data.pageIndex,
          pageSize: response.data.data.pageSize,
          totalItems: response.data.data.totalCount,
          totalPages: response.data.data.pageCount,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to fetch feeds");
        console.error("Failed to fetch feeds", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addFeed = async (newFeed: NewFeed) => {
    setIsLoading(true);
    try {
      let mediaUrl = null;
      if (newFeed.media) {
        mediaUrl = await uploadMedia(newFeed.media);
      }
      const payload: AddUpdateFeedApiRequest = {
        title: newFeed.title,
        description: newFeed.description,
        media: mediaUrl || "",
      };
      const addFeedResponse = await addFeedApi(payload);
      const addedFeed = addFeedResponse.data.data.feed;
      //add feed to feed list
      let updatedFeeds = [addedFeed, ...feeds];
      if (updatedFeeds.length > pagination.pageSize) {
        updatedFeeds.pop();
      }
      setFeeds(updatedFeeds);
      toast.success("Feed added successfully");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to update Reward");
        console.error("Failed to update Reward", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editFeed = async (feed: EditFeed, selectedFeed: Feed) => {
    setIsLoading(true);
    try {
      const payload: AddUpdateFeedApiRequest = {
        title: feed.title,
        description: feed.description,
        media: selectedFeed.media,
      };

      const editFeedResponse = await editFeedApi(selectedFeed._id, payload);
      const updatedFeed = editFeedResponse.data.data.feed;

      // Update the specific feed in the state
      setFeeds((prevFeeds) =>
        prevFeeds.map((f) =>
          f._id === updatedFeed._id ? { ...f, ...updatedFeed } : f
        )
      );

      toast.success("Feed updated successfully");
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to update feed");
        console.error("Failed to update feed", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFeed = async (selectedFeed: Feed | null) => {
    setIsLoading(true);
    if (selectedFeed) {
      try {
        await deleteFeedApi(selectedFeed._id);
        fetchFeeds(pagination.currentPage, pagination.pageSize);
        toast.success("Feed deleted successfully");
      } catch (error) {
        if (error instanceof AxiosError) {
          toast.error(error.response?.data?.message || "Failed to delete feed");
          console.error("Failed to delete feed", error);
        } else {
          toast.error("An unexpected error occurred");
          console.error("Unexpected error", error);
        }
      }
    }
  };

  const handleEditFeed = (selectedFeed: Feed) => {
    setSelectedFeed(selectedFeed);
    setIsAddEditFeedOpen(true);
  };

  const handleDeleteFeed = (selectedFeed: Feed) => {
    setSelectedFeed(selectedFeed);
    setConfirmDelete(true);
  };

  const handleViewMedia = (selectedFeed: Feed) => {
    setSelectedFeed(selectedFeed);
    setIsViewMediaOpen(true);
  };

  const handleViewLike = (selectedFeed: Feed) => {
    setSelectedFeed(selectedFeed);
    setIsViewLikesOpen(true);
  };

  const handleViewComment = (selectedFeed: Feed) => {
    setSelectedFeed(selectedFeed);
    setIsViewCommentsOpen(true);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={header.length} align="center">
            <Spinner />
          </TableCell>
        </TableRow>
      );
    }

    if (feeds.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={header.length} align="center">
            <Typography variant="body1">No data found</Typography>
          </TableCell>
        </TableRow>
      );
    }

    const formatCount = (count: number): string => {
      if (count >= 1000000000) {
        return (count / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
      } else if (count >= 1000000) {
        return (count / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
      } else if (count >= 1000) {
        return (count / 1000).toFixed(1).replace(/\.0$/, "") + "k";
      } else {
        return count.toString();
      }
    };

    const truncateText = (description: string, length: number) => {
      return description.length > length
        ? description.substring(0, length) + "..."
        : description;
    };

    return feeds.map((feed) => (
      <TableRow key={feed._id}>
        <TableCell>
          <Tooltip title={feed.title} placement="bottom" arrow>
            <Typography variant="body2" width={"max-content"}>
              {truncateText(feed.title, 30)}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={feed.description} placement="bottom" arrow>
            <Typography variant="body2" width={"max-content"}>
              {truncateText(feed.description, 20)}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleViewMedia(feed)}>
            <SlideshowIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Button sx={{ gap: 1 }} onClick={() => handleViewComment(feed)}>
            <CommentIcon height={10} width={10} />
            <span>{formatCount(feed?.comments || 0)}</span>
          </Button>
          <Button sx={{ gap: 1 }} onClick={() => handleViewLike(feed)}>
            <FavoriteIcon height={10} width={10} />
            <span>{formatCount(feed?.likedBy?.length || 0)}</span>
          </Button>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleEditFeed(feed)}>
            <EditIcon height={20} width={20} />
          </IconButton>
          <IconButton onClick={() => handleDeleteFeed(feed)}>
            <DeleteIcon height={20} width={20} />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box sx={styles.container}>
      <CustomModal
        open={isAddEditFeedOpen}
        setOpen={setIsAddEditFeedOpen}
        onClose={() => setSelectedFeed(null)}
        sx={styles.addEditFeedModal}
      >
        <AddEditFeedModal
          handleClose={handleClose}
          onAddFeed={addFeed}
          onEditFeed={editFeed}
          selectedFeed={selectedFeed}
        />
      </CustomModal>
      <CustomModal
        open={confirmDelete}
        setOpen={setConfirmDelete}
        onClose={() => setSelectedFeed(null)}
      >
        <DeleteConfirmation
          onClose={handleClose}
          selectedFeed={selectedFeed}
          onConfirm={deleteFeed}
        />
      </CustomModal>
      <CustomModal
        open={isViewMediaOpen}
        setOpen={setIsViewMediaOpen}
        onClose={() => setSelectedFeed(null)}
        sx={styles.viewMediaModal}
      >
        <ViewMediaModal onClose={handleClose} selectedFeed={selectedFeed} />
      </CustomModal>
      <CustomModal
        open={isViewLikesOpen}
        setOpen={setIsViewLikesOpen}
        onClose={() => setSelectedFeed(null)}
        sx={styles.viewLikesModal}
      >
        <ViewLikesModal onClose={handleClose} selectedFeed={selectedFeed} />
      </CustomModal>
      <CustomModal
        open={isViewCommentsOpen}
        setOpen={setIsViewCommentsOpen}
        onClose={() => setSelectedFeed(null)}
        sx={styles.viewCommentsModal}
      >
        <ViewCommentsModal onClose={handleClose} selectedFeed={selectedFeed} />
      </CustomModal>
      <Box sx={styles.tabHeader}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsAddEditFeedOpen(!isAddEditFeedOpen)}
          sx={styles.addFeedBtn}
        >
          <AddIcon sx={styles.addIcon} />
          Add Feed
        </Button>
      </Box>
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table sx={styles.table} aria-label="feeds table">
          <TableHead sx={styles.tableHeader}>
            <TableRow>
              {header.map((header, index) => (
                <TableCell key={index} align={index > 0 ? "center" : "left"}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography fontSize={14} fontWeight={"bold"}>
                      {header}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <Box sx={styles.paginationBox}>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={pagination.totalItems}
          rowsPerPage={pagination.pageSize}
          page={pagination.currentPage - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default FeedsTable;
