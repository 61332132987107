import React, { createContext, useState, ReactNode, useEffect } from "react";
interface AdminContextType {
  user: Admin | null;
  login: (userData: Admin) => void;
  logout: () => void;
  machines: Machine[];
  setAllMachines: (machines: Machine[]) => void;
  addNewMachine: (machine: Machine) => void;
  transactions: Transaction[];
  setAllTransactions: (transactions: Transaction[]) => void;
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<Admin | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const login = (userData: Admin) => {
    setUser(userData);
  };
  const logout = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
  const setAllMachines = (machines: Machine[]) => {
    setMachines(machines);
  };
  const addNewMachine = (machine: Machine) => {
    setMachines([...machines, machine]);
  };
  const setAllTransactions = (transactions: Transaction[]) => {
    setTransactions(transactions);
  };
  return (
    <AdminContext.Provider
      value={{
        user,
        login,
        logout,
        machines,
        setAllMachines,
        addNewMachine,
        transactions,
        setAllTransactions,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const context = React.useContext(AdminContext);
  if (!context) {
    throw new Error("useAdminContext must be used within a UserProvider");
  }
  return context;
};
