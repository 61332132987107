// import { RegisterOptions, UseFormRegisterReturn } from "react-hook-form";
import styles from "./index.module.css";
import { forwardRef } from "react";
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label?: string;
  sublabel?: string;
}
const InputBox = (props: Props, ref: any) => {
  const { label, error, sublabel, ...rest } = props;
  return (
    <div className={styles.inputContainer}>
      <label htmlFor={rest.id} className={styles.inputLablel}>
        {label}
      </label>
      <label htmlFor={rest.id} className={styles.inputLablel}>
        {sublabel}
      </label>
      <input ref={ref} {...rest} />
    </div>
  );
};
export default forwardRef(InputBox);
