import React, { ChangeEvent, useEffect, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../provider/AdminContext";
import axios from "axios";
import logo from "../../assets/TAGETO-logo.png";
import InputBox from "../../components/InputBox";
import { LuEye, LuEyeOff } from "react-icons/lu";
import Spinner from "../../components/Spinner";
import { loginApi } from "../../api/api";
import { HttpClient } from "../../api/axoisInstance";
import { toastify, ToastifyType } from "../../utils/functions";

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAdminContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const validateInputs = () => {
    const errors = {
      email: loginDetails.email.trim() === "",
      password: loginDetails.password.trim() === "",
    };
    return !errors.email && !errors.password;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginDetails((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLogin = async () => {
    if (!validateEmail(loginDetails.email)) {
      toastify({
        type: ToastifyType.ERROR,
        message: "Please fill valid email",
      });
      return;
    }
    if (!validateInputs()) {
      toastify({
        type: ToastifyType.ERROR,
        message: "Please fill in all required fields",
      });
      return;
    }
    try {
      const response = await loginApi(loginDetails);
      const { accessToken, user } = response.data.data;
      login(user);
      localStorage.setItem("token", accessToken);
      HttpClient.setAxiosDefaultConfig();
      navigate("/");
      setIsLoading(false);
    } catch (err) {
      let erorrMessage: string = "";
      if (axios.isAxiosError(err)) {
        if (err.response) {
          erorrMessage = err.response.data.message;
        } else if (err.request) {
          erorrMessage = "No response received from the server";
        } else {
          erorrMessage = err.message;
        }
      } else {
        console.error("Login error:", err);
        erorrMessage = "An unexpected error occurred.";
      }
      toastify({ type: ToastifyType.ERROR, message: erorrMessage });
    }
  };

  return (
    <div className={styles.mainPageContainer}>
      <div className={styles.pageContainer}>
        <div className={styles.imgContainer}>
          <img src={logo} alt="background" className={styles.logo} />
        </div>
        <div className={styles.formContainer}>
          <div>
            <div className={styles.form}>
              <h1 className={styles.firstHeading}>Welcome!</h1>
              <h2 className={styles.secondHeading}>Admin Login</h2>
              <InputBox
                className={styles.inputbox}
                name="email"
                onChange={handleChange}
                placeholder="Enter your email address"
                label="Email"
                type="text"
              />
              <div className={styles.passwordInputContainer}>
                <InputBox
                  className={styles.inputbox}
                  onChange={handleChange}
                  name="password"
                  placeholder="Enter your password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                />
                <span
                  className={styles.showPasswordIcon}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <LuEyeOff /> : <LuEye />}
                </span>
              </div>
              <button
                type="submit"
                className={styles.loginBtn}
                onClick={handleLogin}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Login"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
