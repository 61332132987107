import React from "react";
import { TableCell, TableRow, IconButton, Typography } from "@mui/material";
import styles from "./index.module.css";

export default function TransactionTableRow(props: Transaction) {
  const { _id, amount, userId, type, status, currency, createdAt } = props;

  return (
    <TableRow className={styles.row}>
      <TableCell className={styles.cell} align="left">
        <Typography color="rgba(12, 143, 252, 1)">{userId}</Typography>
      </TableCell>
      <TableCell className={styles.cell} align="left">
        <Typography noWrap sx={{ color: "black" }}>
          {`${currency === undefined || "INR" ? "₹" : currency}${amount}`}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell} align="left">
        <Typography>{`${
          new Date(createdAt).toISOString().split("T")[0]
        }`}</Typography>
      </TableCell>
      <TableCell className={styles.cell} align="left">
        <Typography
          sx={{
            color: `${status === "SUCCESS" ? "#39b239" : "#ca3636"}`,
            fontSize: "0.9rem !important",
            fontWeight: "600",
          }}
        >
          {status}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
