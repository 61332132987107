import axios from 'axios';
import { logout } from '../utils/functions';

const baseURL = process.env.REACT_APP_API_URL;

const setAxiosDefaultConfig = () => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response.data.message == "Not authenticated"){
    logout()
    window.location.href = '/login'
  }
  return Promise.reject(error);
})

const custom = (config = {}) => {
  return axios(config)
}

export const HttpClient = {
  setAxiosDefaultConfig,
  custom,
}



