import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./index.module.css";
import {
  Button,
  TextField,
  Box,
  Typography,
  TablePagination,
} from "@mui/material";
import LevelCard from "../../components/LevelCard";
import AddLevelCard from "../../components/AddLevelCard";
import CustomModal from "../../components/CustomModal";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addLevelApi,
  addRewardApi,
  getLevelsApi,
  updateLevelApi,
  updateRewardApi,
} from "../../api/api";
import Spinner from "../../components/Spinner";
import { AxiosError } from "axios";
import { uploadMedia } from "../../utils/functions";

interface Level {
  _id: string;
  level: number;
  points: number;
}

const getDescription = (num: number) => {
  if (num % 100 >= 11 && num % 100 <= 13) {
    return `${num}th`;
  }
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

const generateRewardCode = (length = 8) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters[randomIndex];
  }

  return result;
}

const Levels: React.FC = () => {
  const [isEditLevel, setIsEditLevel] = useState(false);
  const [isRewardLevel, setIsRewardLevel] = useState(false);
  const [currentLevel, setCurrentLevel] = useState<LevelWithRewards>();
  const [levels, setLevels] = useState<LevelWithRewards[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  });
  const [latestLevel, setLatestLevel] = useState<Level>({
    _id: "",
    points: 0,
    level: 0,
  });

  const fetchLevels = async (
    pageIndex = 1,
    pageSize = 10,
    firstFetch = false
  ) => {
    setIsLoading(true);
    try {
      const response = await getLevelsApi({ pageIndex, pageSize });
      if (response.data.success) {

        const allLevels = response.data.data.levelsWithRewards;
        setLevels(allLevels);

        setPagination({
          currentPage: response.data.data.pagination.currentPage,
          pageSize: response.data.data.pagination.pageSize,
          totalItems: response.data.data.pagination.totalItems,
          totalPages: response.data.data.pagination.totalPages,
        });

        if (firstFetch) {
          const lastLevel = allLevels[0];
          setLatestLevel({
            _id: lastLevel._id,
            points: lastLevel.points,
            level: lastLevel.level,
          });
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to fetch levels");
        console.error("Failed to fetch levels", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addLevel = async (newLevel: AddLevelRequest) => {
    setIsLoading(true);
    try {
      const response = await addLevelApi(newLevel);
      if (response.data.success) {
        console.log(response.data);
        const addedLevel = response.data.data;

        setLevels((prevLevels) => {
          const newLevels = [addedLevel, ...prevLevels];
          if (prevLevels.length > pagination.pageSize) {
            newLevels.pop();
          }
          return newLevels;
        });
        setLatestLevel({
          _id: addedLevel._id,
          points: addedLevel.points,
          level: addedLevel.level,
        });
        setPagination((prev) => ({
          ...prev,
          totalItems: pagination.totalItems + 1,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to add level");
        console.error("Failed to add level", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateLevel = async (currentLevel: LevelWithRewards) => {
    setIsLoading(true);
    try {
      const selectedLevelId = currentLevel._id;
      const updatedData = {
        points: currentLevel.points,
      };
      const response = await updateLevelApi(selectedLevelId, updatedData);
      if (response.data.success) {
        const updatedLevel = response.data.data;
        console.log(updatedLevel);
        setLevels((prevLevel) =>
          prevLevel.map((level) =>
            level._id === updatedLevel._id ? updatedLevel : level
          )
        );
        if (latestLevel._id === updatedLevel._id) {
          setLatestLevel((prevLatestLevel) => ({
            ...prevLatestLevel,
            points: updatedLevel.points,
          }));
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to update level");
        console.error("Failed to update level", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addUpdateReward = async (currentLevel: LevelWithRewards) => {
    setIsLoading(true);
    try {
      const selectedLevel = levels.find((level) => level._id === currentLevel._id);
      let logoUrl = currentLevel?.reward?.logo;
      if (selectedFile) {
        logoUrl = await uploadMedia(selectedFile); 
      }
  
      if (selectedLevel?.reward == null) {
        // Handle adding a reward
        const newReward = {
          level: currentLevel._id,
          rewardCode: generateRewardCode(),
          name: currentLevel?.reward?.name,
          logo: logoUrl, 
          description: `Get 1 ${currentLevel?.reward?.name} reward at ${getDescription(currentLevel.level)} level`,
        };

        const response = await addRewardApi(newReward);
        if (response.data.success) {
          const addedReward = response.data.data;
          const levelId = addedReward.level._id;
          const updatedReward: Reward = {
            ...addedReward,
            level: levelId,
          };
          setLevels((prevLevels) =>
            prevLevels.map((level) =>
              level._id === levelId ? { ...level, reward: updatedReward } : level
            )
          );
          if (latestLevel._id === levelId) {
            setLatestLevel((prevLatestLevel) => ({
              ...prevLatestLevel,
              reward: updatedReward,
            }));
          }
        } else {
          toast.error("Please select a logo file to upload.");
        }
      } else {
        // Handle updating an existing reward
        const selectedRewardId = currentLevel?.reward?._id;
        const updatedData = {
          level: currentLevel._id,
          rewardCode: currentLevel?.reward?.rewardCode,
          name: currentLevel?.reward?.name,
          logo: logoUrl,
          description: `Get 1 ${currentLevel?.reward?.name} reward at ${getDescription(currentLevel.level)} level`,
        };

        const response = await updateRewardApi(selectedRewardId, updatedData);
        if (response.data.success) {
          const updatedRewardResponse = response.data.data;
          const levelId = updatedRewardResponse.level._id;
          const updatedReward: Reward = {
            ...updatedRewardResponse,
            level: levelId,
          };
          setLevels((prevLevels) =>
            prevLevels.map((level) =>
              level._id === levelId ? { ...level, reward: updatedReward } : level
            )
          );
          if (latestLevel._id === levelId) {
            setLatestLevel((prevLatestLevel) => ({
              ...prevLatestLevel,
              reward: updatedReward,
            }));
          }
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message || "Failed to update Reward");
        console.error("Failed to update Reward", error);
      } else {
        toast.error("An unexpected error occurred");
        console.error("Unexpected error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddLevel = () => {
    const lastLevel = latestLevel;
    const newLevelNumber = lastLevel.level + 1;
    const newLevel = {
      level: newLevelNumber,
      points: lastLevel.points + 100,
      description: `You are on ${getDescription(newLevelNumber)} level`,
    };
    addLevel(newLevel);
  };

  const handlePointsChange = (id: string | undefined, value: number) => {
    if (currentLevel) {
      setCurrentLevel({
        ...currentLevel,
        points: value,
      });
    }
  };

  const handleSaveReward = () => {
    if (currentLevel) {
      addUpdateReward(currentLevel);
      setIsRewardLevel(false);
      setPreviewImage(null);
      setSelectedFile(null);
    }
  };

  const handleRewardChange = (
    id: string | undefined,
    newRewardName: string
  ) => {
    if (currentLevel && id === currentLevel._id) {
      setCurrentLevel({
        ...currentLevel,
        reward: {
          ...currentLevel.reward,
          name: newRewardName,
        } as Reward,
      });
    }
  };
  
  const handleEditLevel = () => {
    if (currentLevel) {
      if (
        Number(currentLevel?.points) < 0 ||
        Number(currentLevel?.points) <= 0
      ) {
        toast.error("Please Enter Valid Input", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
        return;
      }
      updateLevel(currentLevel);
      setIsEditLevel(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    }
  };

  useEffect(() => {
    fetchLevels(1, pagination.pageSize, true);
  }, []);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
    fetchLevels(newPage + 1, pagination.pageSize);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
    }));
    fetchLevels(1, parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    setIsEditLevel(false);
    setIsRewardLevel(false);
    setPreviewImage(null);
    setSelectedFile(null);
  };

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_API_URL}/documents/`;
    if (currentLevel?.reward?.logo && !selectedFile) {
      setPreviewImage(`${baseURL}${currentLevel.reward.logo}`);
    }
  }, [currentLevel, selectedFile]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box className={styles.spinnerBox}>
          <Spinner
            sx={{
              width: "60px",
              height: "60px",
            }}
          />
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "600",
              fontFamily: "Poppins",
              textAlign: {
                xs: "center",
                sm: "start",
              },
            }}
          >
            Levels
          </Typography>

          {/* Level Cards Display */}
          <Box className={styles.cardsBox}>
            <AddLevelCard handleAddLevel={handleAddLevel} />
            {levels?.map((level, index) => (
              <LevelCard
                key={index}
                setCurrentLevel={setCurrentLevel}
                setIsEditLevel={setIsEditLevel}
                setIsRewardLevel={setIsRewardLevel}
                level={level}
              />
            ))}
          </Box>
        </Box>
        <CustomModal open={isEditLevel} setOpen={setIsEditLevel} padding={5}>
          <Box className={styles.modalBox}>
            <Typography variant="h5" color={"#E0FD03"} textAlign={"center"}>
              Edit Level
            </Typography>
            <TextField
              label="Points"
              name="points"
              value={currentLevel?.points}
              onChange={(e) => {
                handlePointsChange(currentLevel?._id, +e.target.value);
              }}
              fullWidth
              margin="normal"
              required
              placeholder="100"
              type="number"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
            <Box mt={4} className={styles.modalBtnBox}>
              <Button
                onClick={handleClose}
                variant="outlined"
                className={styles.modalCancelBtn}
              >
                Cancel
              </Button>
              <Button
                onClick={handleEditLevel}
                variant="contained"
                className={styles.modalSaveBtn}
              >
                Save
              </Button>
            </Box>
          </Box>
        </CustomModal>

        <CustomModal
          open={isRewardLevel}
          setOpen={setIsRewardLevel}
          padding={5}
          sx={{ maxWidth: "max-content" }}
          onClose={handleClose}
        >
          <Box className={styles.modalBox}>
            <Typography
              variant="h5"
              color={"#E0FD03"}
              textAlign={"center"}
              mb={2}
            >
              Edit Reward
            </Typography>
            <TextField
              label="Reward"
              name="reward"
              value={currentLevel?.reward?.name || ""}
              onChange={(e) => {
                handleRewardChange(currentLevel?._id, e.target.value);
              }}
              fullWidth
              margin="normal"
              required
              placeholder="Reward"
              type="text"
              autoComplete="off"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
            <TextField
              name="logo"
              onChange={(e) => {
                handleFileChange(e as React.ChangeEvent<HTMLInputElement>);
              }}
              fullWidth
              margin="normal"
              required
              type="file"
              inputProps={{ accept: "image/*" }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  backgroundColor: "black",
                  "& fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&:hover fieldset": {
                    borderColor: "#e0fd03",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#e0fd03",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#e0fd03",
                },
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
            {previewImage && (
              <Box className={styles.previewImageBox}>
                <Box className={styles.imageContainer}>
                  <img
                    src={previewImage}
                    alt="Reward Preview"
                    className={styles.previewImage}
                  />
                </Box>
              </Box>
            )}
            <Box mt={4} className={styles.modalBtnBox}>
              <Button
                onClick={handleClose}
                variant="outlined"
                className={styles.modalCancelBtn}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveReward}
                variant="contained"
                className={styles.modalSaveBtn}
              >
                Save
              </Button>
            </Box>
          </Box>
        </CustomModal>
      </>
    );
  };

  return (
    <Layout>
      <Box className={styles.container}>
        {renderContent()}
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={pagination.totalItems}
            rowsPerPage={pagination.pageSize}
            page={pagination.currentPage - 1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Levels;
