import React, { useEffect } from "react";
import Layout from "../components/Layout";
import MyMachineTable from "../components/MachineList/MyMachineTable";
import { useAdminContext } from "../provider/AdminContext";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAdminContext();
  useEffect(() => {
    if (user === null) {
      navigate("/login");
    }
  }, [user]);
  return (
    <Layout>
      <div>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontFamily: "Poppins",
            textAlign: {
              xs: "center",
              sm: "start",
            },
          }}
        >
          Your Machines
        </Typography>
        <MyMachineTable />
      </div>
    </Layout>
  );
};

export default Home;
